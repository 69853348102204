
@import url('https://fonts.googleapis.com/css2?family=Alata&family=Quicksand:wght@300;500;700&display=swap');

*{
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Quicksand", sans-serif;
  /* min-width: 960px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#site-name{
  font-size: 45px;
  letter-spacing: 1.75px;
}

header, footer{
  background-image: linear-gradient(to right, rgb(5, 98, 98, 100), rgb(5, 98, 98, 0) );
  color: white;
  padding: 10px;
  font-family: "Alata", sans-serif;
}


/* HEADER STYLES */

header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(75, 131, 131, 0.250);
}

.header-link{
  display: inline-block;
  margin: 10px 14px;
  font-size: 25px;
  font-weight: bold;
  color:rgb(5, 98, 98, 100);
}

.header-link a{
  text-decoration: none;
  color: #0c6262;
}

a i{
  color: #0c6262;
}


.header-link:hover{
  cursor: pointer;
  transform: scale(1.25);
}

#download{
  font-size: 20px;
  margin: 4px;
}

main, aside, #skills, #education{
  margin: 20px;
} 

 main *{
  padding: 5px;
} 

/* PROFILE STYLES */

main, aside{
  margin-bottom: 50px;
}

#title-list{
  background-color: rgba(199, 220, 213, 0.431);
  padding: 10px;
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-family: "Alata", sans-serif;
  text-align: center;
}

#title-list li{
  color: rgb(5, 98, 98, 100);
  display: inline-block;
  font-size: 3.5vw;
  font-weight: bold;
  text-align: center;
}

#head-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#profile-pic{
  width: 25%;
  margin: 4.5em;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.description-fill, #key-desc{
  font-size: 30px;
}

#key-desc{
  font-weight: bold;
}

.emphasis{
  color: teal;
  font-weight: bold;
}

#mail-link{
  text-decoration: none;
  color: teal;
}

#mail-link:hover{
  text-decoration: none;
  color: rgb(59, 196, 196);
}




/* PROJECT STYLES */
.project-image{
  width:95%;
  padding: 10px;
  height: auto;
  margin: 0px;
  object-fit: cover;
  border-radius: 20px;
  margin: 10px;
}


.popout{
  transition: transform .5s;
}


#project-head{
  text-align: center;
  padding: 20px;
  font-size: 45px;
  color: teal;
  font-family: "Alata", sans-serif;
}

#project-area{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.project-container{
  display: flex;
  flex: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 0px;
  border-radius: 8px;
  margin: 5px 10em;
  
  background-color: rgb(226, 238, 238);
}

.proj-img-box{
  max-width: 100%;
}


.proj-desc, .proj-title{
  padding: 8px ;
  text-align: center;
}

.proj-text{
  display: flex;
  width: 40%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  padding: 10px;
}

.proj-title{
  font-size: 30px;
}

.proj-desc{
  font-size: 18px;
  text-align: left;
}

a {
  text-decoration: none;
}

.proj-skills{
  padding-left: 0;
}

.proj-skills li{
  display: inline-block;
  margin: 3px 6px;
}

.proj-skills li i{
  font-size: 40px;
}

.demo {
  color: teal;
  font-size: 20px;
  border: 4px solid teal;
  padding: 6px;
  margin: 4px;
  border-radius: 8px;
}

.demo:hover{
  background-color: teal;
  color: white;
}


/* SKILL STYLES */

#skills{
  text-align: right;
  color: teal;
  font-size: 45px;
  font-family: "Alata", sans-serif;
}

#skills h3{
font-size: 45px;
padding: 8px;
color: white;
padding: 10px;
background-color: teal;
border-radius: 45px 45px 3px 3px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

#skill-list{
display: flex;
justify-content: space-evenly;
/* background-color: teal;
border-radius: 45px 45px 3px 3px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
margin-bottom: 50px;
margin-top: 15px;
padding-left: 0px;
}

#skill-list li{
display: inline-block;
margin: 15px;
}

i{
font-size: 85px;
color: teal;
}



/* EDUCATION STYLES */
#ed-head{
  font-size: 45px;
  color: white;
  padding: 10px;
  background-color: teal;
  border-radius: 45px 45px 3px 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  font-family: "Alata", sans-serif;
}

#education-container{
  display: flex;
  justify-content: space-evenly;
  /* border-radius:3px 3px 45px 45px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  margin-bottom: 50px;
  margin-top: 15px;
}


.ed-card{
  text-align: center;
  margin: 20px;
}

.ed-card h4{
  font-size: 30px;
  color: teal;
  text-decoration:underline;
}

.ed-program{
  font-weight: bold;
  font-size: 18px;
}

/* FOOTER STYLES */

footer{
  border-top: 1px solid rgba(75, 131, 131, 0.250);
  display: flex;
  justify-content: space-between;
}

#social-icons li{
  display: inline-block;
  margin-left: 25px;
}

.social{
  color: teal;
  font-size: 40px;
}

.social:hover{
 transform: scale(1.25);
}

/* RESPONSIVE SECTIONS */

@media screen and (min-width: 1300px){
  .project-image{
    width: 800px;
  }
}

@media screen and (max-width: 655px){
  header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(75, 131, 131, 0.250);
    background-image: linear-gradient(to right, rgb(5, 98, 98, 100), rgb(5, 98, 98, 0), 85% );
  }
  header ul{
    padding-left: 0px;
  }
  .description-fill, #key-desc{
    font-size: 4vw;
  }

  #title-list li{
    color: rgb(5, 98, 98, 100);
    display: inline-block;
    font-size: 3.5vw;
    font-weight: bold;
    text-align: center;
  }

  #profile-pic{
    width: 30%;
    margin:2em;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }


  .project-image{
    width: 90%;
    align-self: center;
  }

}

@media screen and (max-width: 1060px){
  #project-area{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .project-container{
    display: flex;
    align-self: center;
    flex: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 0px;
    border-radius: 8px;
    width: 90%;
    margin: 5px;
    background-color: rgb(226, 238, 238);
  }

  .proj-text{
    width: 75%;
  }

}


@media screen and (max-width: 1300px){
  #head-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .project-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .proj-title{
    font-size: 20px;
  }
  
  .proj-desc{
    font-size: 15px;
  }
}




@media screen and (max-width: 930px){
  
  #profile-pic{
    margin:2em;
  }
  
  #skills{
    text-align: center;
    color: teal;
    font-size: 45px;
    font-family: "Alata", sans-serif;
  }

  #skills h3, #project-head, #ed-head{
    font-size: 5vw;
    text-align: center;
    }

  i{
    font-size: 7vw;
    color: teal;
    }

    #education-container{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: rgba(199, 220, 213, 0.431);
      border-radius:3px 3px 45px 45px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      margin-bottom: 50px;
      margin-top: 15px;
    }
}

